// 创建 svg
export function createSVG() {
    $(".svg").each(function() {
        var svg = '<svg class="icon" aria-hidden="true"><use xlink:href="' + $(this).attr("data-icon") + '"></use></svg>';
        $(this).html(svg);
    });
}


export function show(id) {
    $(id).addClass("show");
    $(id).removeClass("hide");
}

export function hide(id) {
    $(id).addClass("hide");
    $(id).removeClass("show");
}


export function addClass(id, name) {
    $(id).addClass(name);
}

export function removeClass(id, name) {
    $(id).removeClass(name);
}


// 图片占位图、加载失败
function img_failed(){
	$(".box img").error(function() {
		$(this).replaceWith("<div class='img-failed'></div>");
		$('.box div[class~="img-failed"]').parent().removeClass("img-placeholder");
	});
	$('.box img[class~="lazyload"]').parent().addClass("img-placeholder");
    $('.box img[class~="lazyloading"]').parent().addClass("img-placeholder");

	$(".box img").each(function() {
		var observer = new MutationObserver(function(mutationsList, ob) {
			for (let mutation of mutationsList) {
				if (mutation.type !== 'attributes') {
					return;
				}
				$('.box img[class~="lazyloaded"]').parent().removeClass("img-placeholder");
			}
		});
		observer.observe(this, {
			attributes: true,
			attributeFilter: ['class']
		});
	});
}




export function main() {
    createSVG();
    img_failed();


	$(".image.loading").each((_, e) => {
		$(e).children("img").addClass("lazyload")
	});
    $(".image > img")
        .error(e => {
            $(e.currentTarget).parent().addClass("error")
            $(e.currentTarget).parent().removeClass("loading")
        })
        .load(e => {
            $(e.currentTarget).parent().removeClass("loading")
        })

	// 粘合侧边，修改顶部定位位置
	// $(document).ready(function() {
	// 	$('.area-main, .area-aside')
	// 		.theiaStickySidebar({
	// 			additionalMarginTop: 100,
	// 		});
	// });

	// 粘合侧边，修改顶部定位位置
	// $(document).ready(function() {
	// 	$('.rank-main, .rank-nav')
	// 		.theiaStickySidebar({
	// 			additionalMarginTop: 100,
	// 		});
	// });

	// 文章详情图片预览，增加a标签
	// $(".article-content img").each(function(index, element) {
	// 	$link = $("<a class='article-image' href=" + $(element).attr("src") + " data-lightbox='article-image'></a>")
	// 	$link.append($(element).clone(true, true))
    //     $(element).replaceWith($link)
    // })

	// 图片预览,参数修改
	// lightbox.option({
	// 	'resizeDuration': 450,
	// 	'wrapAround': true,
	// 	'albumLabel':" %1 / %2",
	// 	'wrapAround': true,
	// 	'positionFromTop': 80,
	//   })

    // $(".article-content img").css({"width": "auto", "height": "auto"});
}
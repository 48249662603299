import { Swiper } from "swiper";
import { Navigation, Pagination, Autoplay, FreeMode } from "swiper/modules";

import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import "@src/assets/common.css";
import "@style/style.css";
import "@src/assets/content.css";
import "@src/assets/style.css";

import { main } from "@script/_base.js";
import "@script/lazysizes.min.js";

import "theia-sticky-sidebar";

$(main);

$(function () {
    // svg
    const text = $(".gradient-svg > svg").html();
    $(".gradient-svg > svg").html(
        `
        <defs>
            <linearGradient id="gradient-color" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" class="start" />
                <stop offset="100%" class="end" />
            </linearGradient>
        </defs>
        ${text}
        `
    );

    const direction = window.getComputedStyle(document.getElementsByTagName("html")[0]).direction;

    //
    const swiper = new Swiper(".js-index-banner-swiper", {
        modules: [Pagination, Autoplay],
        direction: "horizontal",
        loop: true,
        autoplay: {
            enabled: false,
        },
        pagination: {
            el: ".swiper-pagination",
            bulletElement: "li",
            clickable: true,
            //   dynamicBullets: true,
        },
    });

    const screenshotSwiper = new Swiper(".js-app-screenshot-swiper", {
        modules: [FreeMode, Navigation],
        direction: "horizontal",
        spaceBetween: 12,
        slidesPerView: "auto",
        freeMode: {
            enabled: true,
            sticky: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    const images = document.querySelector("#js-images-id");
    if (images) {
        const viewer = new Viewer(images, {
            url(image) {
                if (image.src) {
                    return image.src;
                } else {
                    return image.attributes["data-src"];
                }
            },
            loop: false,
            navbar: false,
            fullscreen: true,
            movable: false,
            zIndex: 99999999,
            minZoomRatio: 0.5,
            maxZoomRatio: 2,
        });
    }

    $(".content > .main, .content > .aside").theiaStickySidebar({
        additionalMarginTop: 80,
    });
});